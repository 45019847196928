<template>
  <div id="mian">
    <div>
      <div class="content">
        <h6>
          订单信息
          <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
        </h6>
        <ul class="ul_left">
          <li><span>代付订单号</span><i>2013423343245</i></li>
          <li><span>三方代付订单号</span><i>2013423343245</i></li>
          <li><span>商户名称</span><i>2013423343245</i></li>
          <li><span>所属机构</span><i>2013423343245</i></li>
          <li><span>创建时间</span><i>2013423343245</i></li>
          <li><span>产品类型</span><i>2013423343245</i></li>
          <li><span>账户卡号</span><i>2013423343245</i></li>
          <li><span>交易金额</span><i>2013423343245</i></li>
          <li><span>总金额</span><i>2013423343245</i></li>
          <li><span>说明</span><i>2013423343245</i></li>
          <li><span>代付应答码</span><i>2013423343245</i></li>
          <li><span>代付明细状态码</span><i>2013423343245</i></li>
        </ul>
        <ul class="ul_right">
          <li><span>代付流水号</span><i>2013423343245</i></li>
          <li style="height: 19px;"></li>
          <li><span>商户编号</span><i>2013423343245</i></li>
          <li><span>渠道商户号</span><i>2013423343245</i></li>
          <li><span>创建时间</span><i>2013423343245</i></li>
          <li><span>交易状态</span><i>2013423343245</i></li>
          <li><span>账户名称</span><i>2013423343245</i></li>
          <li><span>手续费</span><i>2013423343245</i></li>
          <li><span>代付用途</span><i>2013423343245</i></li>
          <li><span>通知地址</span><i>2013423343245</i></li>
          <li><span>代付批次状态码</span><i>2013423343245</i></li>
          <li><span>异常错误码</span><i>2013423343245</i></li>
        </ul>
      </div>
      <div class="tab1" style="margin-bottom: 18px;">
        <h6>通知信息</h6>
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 286px;">
              <p>通知时间</p>
            </td>
            <td style="width:178px">
              <p>请求类型</p>
            </td>
            <td style="width:292px">
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
          </tr>
          <tr>
            <td style="width: 286px;">
              <p>2019-01-12 15:51:15</p>
            </td>
            <td style="width:178px">
              <p>第三方</p>
            </td>
            <td style="width:292px">
              <p>通知到达</p>
            </td>
            <td>
              <p>成功</p>
            </td>
          </tr>
          <tr>
            <td style="width: 286px;">
              <p>2019-01-12 15:51:15</p>
            </td>
            <td style="width:178px">
              <p>第三方</p>
            </td>
            <td style="width:292px">
              <p>通知到达</p>
            </td>
            <td>
              <p>成功</p>
            </td>
          </tr>
          <tr>
            <td style="width: 286px;">
              <p>2019-01-12 15:51:15</p>
            </td>
            <td style="width:178px">
              <p>第三方</p>
            </td>
            <td style="width:292px">
              <p>通知到达</p>
            </td>
            <td>
              <p>成功</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        crumbs: "交易详情",
      }
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
  .lianjie {
    color: #008AFF;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 75px;
    color: #999999;
    margin-left: 15px;
  }

  .tab tr td p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }

  .tab_title {
    background: rgba(244, 245, 246, 1);
    border-top: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 .tab tr {
    padding-left: 20px;
    height: 60px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab {
    border-collapse: collapse;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    float: none;
  }

  .ul_right {
    float: left;
  }

  .ul_right li i {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }

  .ul_right li span {
    display: inline-block;
    width: 140px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }

  .ul_right li {
    padding-bottom: 18px;
  }

  .ul_left li:last-child {
    padding-bottom: 0;
  }

  .ul_right li:last-child {
    padding-bottom: 0;
  }

  .ul_left li i {
    display: inline-block;
    width: 290px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .ul_left li span {
    display: inline-block;
    width: 140px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }

  .ul_left li {
    padding-bottom: 18px;
  }

  .ul_left {
    float: left;
    padding-left: 45px;
  }

  .content {
    width: 100%;
    overflow: hidden;
  }

  .content h4 {
    padding-left: 15px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 75px;
  }

  .header .active {
    color: rgba(72, 184, 182, 1);
    border-bottom: 3px solid #48B8B6;
  }

  .header span {
    text-align: center;
    display: inline-block;
    width: 100px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }

  .header {
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    padding-top: 20px;
  }

  #mian {
    width: 96.5%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    padding: 0px 20px 0 20px;
    overflow-y: auto;
  }

  .addBt {
    width: 60px;
    height: 32px;
    background-color: #48B8B6;
    color: #FFFFFF;
    font-size: 12px;
    padding: 0;
  }

  .exitBt {
    width: 60px;
    height: 32px;
    background-color: #ffffff;
    color: #333333;
    font-size: 12px;
    padding: 0;
  }

  .overAccount_ul {
    width: 490px;
    float: left;
    overflow: hidden;
  }

  .overAccount_ul_title {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    color: #999999;
  }

  .overAccount_ul .el-input,
  .overAccount_ul .el-select {
    margin-bottom: 7px;
  }

  .ul-left {
    float: left;
    width: 200px;
    margin-left: 24px;
  }

  .ul-right {
    float: right;
    width: 200px;
    margin-right: 26px;
  }
  .reEmailBt {
    float: right;
    margin-top: 24px;
    background: #48B8B6;
    border-color: #5AAFAB;
    width: 90px;
    height: 36px;
    padding: 0;
  }
</style>
